import Presentes from "../Presentes/Presentes"
import "./lista.css"

export default function Lista() {
    return (
        <div className="lista container-fluid">
            <div className="lista-de-presentes container-fluid">
                <Presentes 
                    img="../../../images/panelas.jpg"
                    titulo="Conjunto de Panelas"
                    preco="180,00"
                />
                <Presentes 
                    img="../../../images/geladeira.jpg"
                    titulo="Geladeira"
                    preco="3000,00"
                />
                <Presentes 
                    img="../../../images/cafeteira-min.png"
                    titulo="Cafeteira"
                    preco="600,00"
                />
                <Presentes 
                    img="../../../images/airfryer.jpg"
                    titulo="Air Fryer"
                    preco="650,00"
                />
                <Presentes 
                    img="../../../images/microondas.jpg"
                    titulo="Micro-ondas"
                    preco="800,00"
                />
                <Presentes 
                    img="../../../images/sanduicheira.jpg"
                    titulo="Sanduicheira"
                    preco="150,00"
                />
                <Presentes 
                    img="../../../images/waffle.jpg"
                    titulo="Máquina de Waffles"
                    preco="140,00"
                />
                <Presentes 
                    img="../../../images/ferro.jpg"
                    titulo="Ferro de Passar"
                    preco="100,00"
                />
                <Presentes 
                    img="../../../images/tacas.jpg"
                    titulo="Conjunto de Taças"
                    preco="190,00"
                />
                <Presentes 
                    img="../../../images/pratos.jpg"
                    titulo="Aparelho de Jantar"
                    preco="170,00"
                />
                <Presentes 
                    img="../../../images/faqueiro.jpg"
                    titulo="Faqueiro"
                    preco="130,00"
                />
                <Presentes 
                    img="../../../images/liquidificador-min.png"
                    titulo="Liquidificador"
                    preco="110,00"
                />
            </div>

            <h1>Cotas de Lua de Mel</h1>

            <div className="lista-de-presentes container-fluid">
                <Presentes 
                    img="../../../images/hotel.jpg"
                    titulo="Diária da Hospedagem"
                    preco="500,00"
                />
                <Presentes 
                    img="../../../images/jantar.jpg"
                    titulo="Jantar Romântico"
                    preco="250,00"
                />
                <Presentes 
                    img="../../../images/cafe.jpg"
                    titulo="Café da Manhã Especial"
                    preco="150,00"
                />
                <Presentes 
                    img="../../../images/story_pipa_centro.jpg"
                    titulo="Ingressos para Atrações Locais"
                    preco="650,00"
                />
                <Presentes 
                    img="../../../images/spa.jpeg"
                    titulo="SPA a Dois"
                    preco="400,00"
                />
                <Presentes 
                    img="../../../images/translado.jpg"
                    titulo="Translado Ida e Volta"
                    preco="900,00"
                />
                <Presentes 
                    img="../../../images/city.jpg"
                    titulo="City Tour"
                    preco="350,00"
                />
                <Presentes 
                    img="../../../images/mergulho.jpg"
                    titulo="Mergulho nas Águas de Pipa"
                    preco="160,00"
                />
                <Presentes 
                    img="../../../images/quadriciclo.jpg"
                    titulo="Passeio de Quadriciclo"
                    preco="120"
                />
                <Presentes 
                    img="../../../images/buggy.png"
                    titulo="Passeio de Buggy"
                    preco="350"
                />
                <Presentes 
                    img="../../../images/golfinhos.jpg"
                    titulo="Mergulho com Golfinhos"
                    preco="200,00"
                />
                <Presentes 
                    img="../../../images/almoco.jpg"
                    titulo="Almoço na Praia"
                    preco="130,00"
                />
            </div>
        </div>
    )
}