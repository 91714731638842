import Header from "../Header/Header"
import "./footer.css"

export default function Footer() {
    return(
        <div className="container-fluid footer">
            <div className="div-footer-img">
                <img className="img-fluid footer-img" src="../../../images/L&N adesivo circular_transparente.png" loading="lazy"/>
            </div>
            <div className="links-footer">
                <Header/>
            </div>
            <div className="texto-footer">
                A presença e o carinho de cada um de vocês tornam este momento ainda mais especial. Estamos ansiosos para compartilhar este dia inesquecível com todos.
            </div>
        </div>
    )
}