import { useEffect, useState } from "react";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../config/firebase/firebase"; // Certifique-se de importar o `db`
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import './convidados.css';

export default function Convidados() {
    const [nomesConfirmados, setNomesConfirmados] = useState([]);
    const [nomesAusentes, setNomesAusentes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribeConfirmados = onSnapshot(
            doc(db, 'confirmacoes_presenca', 'confirmados'),
            (doc) => {
                const data = doc.data();
                setNomesConfirmados(data?.presenca || []);
                setLoading(false);
            },
            (error) => {
                console.error('Erro ao ouvir nomes confirmados:', error);
                setLoading(false);
            }
        );

        const unsubscribeAusentes = onSnapshot(
            doc(db, 'confirmacoes_ausencia', 'ausentes'),
            (doc) => {
                const data = doc.data();
                setNomesAusentes(data?.ausentes || []);
                setLoading(false);
            },
            (error) => {
                console.error('Erro ao ouvir nomes ausentes:', error);
                setLoading(false);
            }
        );

        // Cleanup function to unsubscribe from snapshots
        return () => {
            unsubscribeConfirmados();
            unsubscribeAusentes();
        };
    }, []);

    if (loading) {
        return <p>Carregando...</p>;
    }

    return (
        <div className="convidados-div">
            <a className="back-to-start" href="/">
                <img src="../../../images/de-volta.png"></img>
            </a>
            <table className="table table-borderless table-convidados">
                <thead>
                    <tr>
                        <th scope="col">Nomes Confirmados</th>
                        <th scope="col">Confirmações Pendentes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {nomesConfirmados.map((nome, index) => (
                                <p key={index}>{index + 1}. {nome}</p>
                            ))}
                        </td>
                        <td>
                            {nomesAusentes.map((nome, index) => (
                                <p key={index}>{index + 1}. {nome}</p>
                            ))}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
