import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "../components/Login/Login";
import { AuthProvider, useAuth } from "../contexts/AuthContext";
import Home from "../components/Home/Home.jsx";
import Convidados from "../components/Convidados/Convidados.jsx";
import Mensagem from "../components/Mensagem/Mensagem.jsx";
import { esvaziarCarrinho } from "../servicos/firestore.js";

// Lista de e-mails autorizados
const allowedEmails = ["noivos@casamentoln.com", "adeline@casamentoln.com"];

function PrivateRoute({ children }) {
    const { user, loading } = useAuth();

    if (loading) return (
        <div className="row custom-row-loader">
            <div className="col-md-12">
                <div id="preloader" className="mb-5">
                    <div className="inner inner-custom">
                        <div className="bolas">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return user ? children : <Navigate to="/login" />;
}

function PublicRoute({ children }) {
    const { user, loading } = useAuth();

    if (loading) return (
        <div className="row custom-row-loader">
            <div className="col-md-12">
                <div id="preloader" className="mb-5">
                    <div className="inner inner-custom">
                        <div className="bolas">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return user ? <Navigate to="/" /> : children;
}

function AuthorizedEmailRoute({ children }) {
    const { user } = useAuth();
    
    if (!user || !user.email) {
        console.log("Nenhum usuário autenticado ou e-mail não disponível");
        return <Navigate to="/" />;
    }

    // Função para extrair a parte local do e-mail
    const getLocalPart = (email) => email.split('@')[0];

    // Parte local do e-mail do usuário
    const userLocalPart = getLocalPart(user.email).trim().toLowerCase();

    // Lista de e-mails autorizados com a parte local extraída
    const allowedLocalParts = allowedEmails.map(email => getLocalPart(email).toLowerCase());

    // Verifica se a parte local do e-mail do usuário está na lista autorizada
    const isAuthorized = allowedLocalParts.includes(userLocalPart);

    return isAuthorized ? children : <Navigate to="/" />;
}


export function AppRoutes() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/login"
                        element={
                            <PublicRoute>
                                <Login />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Home />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/pagamento-confirmado"
                        element={
                            <PrivateRoute>
                                <AuthorizedEmailRoute>
                                    <Mensagem 
                                        mensagem="Agradecemos pelo carinho e pela generosidade! Seu presente foi recebido com sucesso 
                                        e já está nos ajudando a construir os nossos sonhos. Estamos muito felizes em ter você ao nosso lado nesse momento especial. 
                                        Em breve, entraremos em contato para expressar a nossa gratidão. Com carinho, Luziane e Nícolas." 
                                        esvaziarCarrinho={esvaziarCarrinho}
                                    />
                                </AuthorizedEmailRoute>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/pagamento-pendente"
                        element={
                            <PrivateRoute>
                                <AuthorizedEmailRoute>
                                    <Mensagem 
                                        mensagem="Agradecemos pelo carinho e pela generosidade! Seu presente está sendo processado e, em breve, 
                                        estará nos ajudando a construir os nossos sonhos. Estamos muito felizes em ter você ao nosso lado nesse momento especial. 
                                        Assim que o pagamento for confirmado pela plataforma, entraremos em contato para expressar a nossa gratidão. Com carinho, Luziane e Nícolas." 
                                        esvaziarCarrinho={esvaziarCarrinho}
                                    />
                                </AuthorizedEmailRoute>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/pagamento-negado"
                        element={
                            <PrivateRoute>
                                <AuthorizedEmailRoute>
                                    <Mensagem 
                                        mensagem="Agradecemos pelo carinho e pela generosidade! Infelizmente, 
                                        houve um problema com o processamento do seu pagamento e ele não foi concluído. 
                                        Agradecemos de coração pela intenção e pedimos que, se possível, tente novamente ou entre em contato conosco para que possamos ajudar. 
                                        Com carinho, Luziane e Nícolas." 
                                    />
                                </AuthorizedEmailRoute>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/convidados"
                        element={
                            <PrivateRoute>
                                <AuthorizedEmailRoute>
                                    <Convidados />
                                </AuthorizedEmailRoute>
                            </PrivateRoute>
                        }
                    />
                     <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}
