import "./endereco.css"

export default function Endereco () {
    return(
        <div className="endereco-div container-fluid">
            <div className="container-fluid custom-width">
                <div className="desc-endereco">
                    <h3>
                        O Evento
                    </h3>
                    <h5>Local: Hora Mágica Buffet</h5>
                    <div className="infos-buffet">
                        <span className="margin"><img className="icon-mapa" src="../../../images/icon-mapa.png" loading="lazy"/> Avenida João Moreira de Paula , 4575 Rio novo, Cascavel - CE, 62850-000</span>
                        <span className="margin block"><img className="icon-calendario" src="../../../images/calendario.png" loading="lazy"/> Sexta-Feira, 15 de novembro de 2024, 17:00</span>
                    </div>
                </div>
            </div>

            <div className="buffet custom-width">
                <div className="buffet-pick">
                    <img id="first-buffet-pick" className="img-fluid" src="../../../images/hm2.jpg" loading="lazy"/>
                    <img width={190} id="second-buffet-pick" className="img-fluid" src="../../../images/horamagica1.jpg" loading="lazy"/>
                </div>
            </div>
        </div>
    )
}