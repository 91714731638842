import { db, auth } from "../config/firebase/firebase";
import { arrayUnion, arrayRemove, collection, addDoc, getDocs, doc, setDoc, updateDoc, getDoc, deleteDoc, query, where, onSnapshot, serverTimestamp, orderBy, deleteField, writeBatch } from "firebase/firestore";
import { useState } from "react";

export async function salvarConfirmacaoPresenca(userId) {
    try {
        const docRef = doc(db, 'checar_confirmacao', 'confirmado');
        await setDoc(docRef, { [userId]: true }, { merge: true });
        console.log('Presença confirmada com sucesso!');
    } catch (error) {
        console.error('Erro ao salvar a confirmação de presença:', error);
    }
}

export async function verificarCheck(userId) {
    try {
        const docRef = doc(db, 'checar_confirmacao', 'confirmado');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data()[userId]) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Erro ao verificar a confirmação de presença:', error);
        return false;
    }
}

export async function esvaziarCarrinho() {
    const user = auth.currentUser;

    if (user) {
        const userId = user.uid;
        try {
            // Obter todos os documentos na coleção de itens do carrinho
            const querySnapshot = await getDocs(collection(db, `carrinhos/${userId}/itens`));
            
            // Excluir cada documento (item do carrinho)
            const deletePromises = querySnapshot.docs.map((documento) => deleteDoc(doc(db, `carrinhos/${userId}/itens`, documento.id)));
            
            // Aguarda a exclusão de todos os itens
            await Promise.all(deletePromises);
            
            console.log('Carrinho esvaziado com sucesso');
        } catch (error) {
            console.log('Erro ao esvaziar o carrinho:', error);
        }
    } else {
        console.log('Usuário não autenticado');
    }
}

export async function salvarPresente(data) {
    const user = auth.currentUser;

    if (user) {
        const userId = user.uid;
        try {
            await addDoc(collection(db, `carrinhos/${userId}/itens`), data);
            return 'sucesso';
        } catch (error) {
            console.log('Erro:', error);
            return 'erro';
        }
    } else {
        console.log('Usuário não autenticado');
        return 'erro';
    }
}

export async function pegarPresentes() {
    const user = auth.currentUser;

    if (user) {
        const userId = user.uid;
        try {
            const querySnapshot = await getDocs(collection(db, `carrinhos/${userId}/itens`));
            let carrinho = [];
            querySnapshot.forEach((doc) => {
                console.log(doc.id, " => ", doc.data());
                let item = { id: doc.id, ...doc.data() };
                carrinho.push(item);
            });
            return carrinho;
        } catch (error) {
            console.log('Erro:', error);
            return [];
        }
    } else {
        console.log('Usuário não autenticado');
        return [];
    }
}

export async function pegarPresentesTempoReal(setCarrinho) {
    const user = auth.currentUser;

    if (user) {
        const userId = user.uid;
        const ref = query(collection(db, `carrinhos/${userId}/itens`), orderBy('timestamp'));
        onSnapshot(ref, (querySnapshot) => {
            const itensMap = new Map();

            querySnapshot.forEach((doc) => {
                const item = doc.data();
                const key = item.titulo; // or another unique identifier for your items
                if (itensMap.has(key)) {
                    itensMap.get(key).quantidade += 1;
                } else {
                    itensMap.set(key, { id: doc.id, quantidade: 1, ...item });
                }
            });

            const carrinho = Array.from(itensMap.values());
            setCarrinho(carrinho);
        });
    } else {
        console.log('Usuário não autenticado');
    }
}

export async function adicionarItem(titulo, preco) {
    const user = auth.currentUser;

    if (user) {
        const userId = user.uid;
        try {
            await addDoc(collection(db, `carrinhos/${userId}/itens`), {
                titulo,
                preco,
                timestamp: serverTimestamp()
            });
            console.log('Item adicionado com sucesso');
        } catch (error) {
            console.log('Erro ao adicionar item:', error);
        }
    } else {
        console.log('Usuário não autenticado');
    }
}

// Função para remover um item do carrinho
export async function removerItem(titulo) {
    const user = auth.currentUser;

    if (user) {
        const userId = user.uid;
        try {
            const q = query(collection(db, `carrinhos/${userId}/itens`), where('titulo', '==', titulo));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const docId = querySnapshot.docs[0].id; // Pegue o ID do primeiro documento correspondente
                await deleteDoc(doc(db, `carrinhos/${userId}/itens`, docId));
                console.log('Item removido com sucesso');
            }
        } catch (error) {
            console.log('Erro ao remover item:', error);
        }
    } else {
        console.log('Usuário não autenticado');
    }
}

// Funções de presença

// Verifica se o usuário já confirmou a presença
export async function verificarConfirmacaoPresenca(userId) {
    const q = query(collection(db, 'confirmacoes_presenca'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
}

// Verifica se o usuário já recusou a presença
export async function verificarRecusaPresenca(userId) {
    const q = query(collection(db, 'confirmacoes_ausencia'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
}

// Função para confirmar ausência
export async function confirmarAusencia(userId, nomesAusentes) {
    try {
        const docRef = doc(db, 'confirmacoes_ausencia', 'ausentes');
        const docSnap = await getDoc(docRef);

        let nomesAtualizados = nomesAusentes;

        if (docSnap.exists()) {
            // Obtém os nomes já existentes
            const dados = docSnap.data();
            const ausentesAtuais = dados.ausentes || [];
            // Adiciona novos ausentes aos já existentes
            nomesAtualizados = [...new Set([...ausentesAtuais, ...nomesAusentes])];
        }

        // Atualiza ou cria o documento com os nomes atualizados
        await setDoc(docRef, {
            ausentes: nomesAtualizados
        });

        // Remove os nomes ausentes dos confirmados
        await removerDePresentes(nomesAusentes);

    } catch (error) {
        console.error('Erro ao confirmar ausência:', error);
        throw error;
    }
}

// Função para confirmar presença
export async function confirmarPresenca(userId, nomesSelecionados) {
    try {
        const docRef = doc(db, 'confirmacoes_presenca', 'confirmados');
        const docSnap = await getDoc(docRef);

        let nomesAtualizados = nomesSelecionados;

        if (docSnap.exists()) {
            // Obtém os nomes já existentes
            const dados = docSnap.data();
            const confirmadosAtuais = dados.presenca || [];
            // Adiciona novos confirmados aos já existentes
            nomesAtualizados = [...new Set([...confirmadosAtuais, ...nomesSelecionados])];
        }

        // Atualiza ou cria o documento com os nomes atualizados
        await setDoc(docRef, {
            presenca: nomesAtualizados
        });

        // Remove os nomes confirmados dos ausentes
        await removerDeAusentes(nomesSelecionados);

    } catch (error) {
        console.error('Erro ao confirmar presença:', error);
        throw error;
    }
}


export async function atualizarPresencaEAusencia(nomesSelecionados) {
    try {
        // Recupera nomes atualmente confirmados e ausentes
        const nomesConfirmados = await recuperarNomesConfirmados();
        const nomesAusentes = await recuperarNomesAusentes();

        // Atualiza o array de ausência
        await updateDoc(doc(db, 'confirmacoes_ausencia', 'ausentes'), {
            ausentes: arrayRemove(...nomesConfirmados)
        });

        // Remove os nomes dos confirmados que foram adicionados aos ausentes
        await updateDoc(doc(db, 'confirmacoes_presenca', 'confirmados'), {
            presenca: arrayUnion(...nomesSelecionados)
        });

    } catch (error) {
        console.error('Erro ao atualizar presença e ausência:', error);
        throw error;
    }
}

export async function recuperarNomesConfirmados() {
    try {
        // Referência ao documento 'confirmados' na coleção 'confirmacoes_presenca'
        const docRef = doc(db, 'confirmacoes_presenca', 'confirmados');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            const nomesConfirmados = data.presenca || []; // Retorna os nomes confirmados, ou um array vazio se não houver dados
            
            return nomesConfirmados;
        } else {
            console.log("Nenhum dado encontrado para 'confirmados'.");
            return [];
        }
    } catch (error) {
        console.error('Erro ao recuperar nomes confirmados:', error);
        throw error;
    }
}

export async function recuperarNomesAusentes() {
    try {
        // Referência ao documento 'ausentes' na coleção 'confirmacoes_ausencia'
        const docRef = doc(db, 'confirmacoes_ausencia', 'ausentes');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            const nomesAusentes = data.ausentes || []; // Retorna os nomes ausentes, ou um array vazio se não houver dados
            return nomesAusentes;
        } else {
            console.log("Nenhum dado encontrado para 'ausentes'.");
            return [];
        }
    } catch (error) {
        console.error('Erro ao recuperar nomes ausentes:', error);
        throw error;
    }
}

export async function removerDeAusentes(nomesSelecionados) {
    try {
        const docRef = doc(db, 'confirmacoes_ausencia', 'ausentes');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            await updateDoc(docRef, {
                ausentes: arrayRemove(...nomesSelecionados)
            });
        } 
    } catch (error) {
        console.error('Erro ao confirmar ausência:', error);
        throw error;
    }
}

export async function removerDePresentes(nomesAusentes) {
    try {
        const docRef = doc(db, 'confirmacoes_presenca', 'confirmados');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            await updateDoc(docRef, {
                presenca: arrayRemove(...nomesAusentes)
            });
        } 
    } catch (error) {
        console.error('Erro ao confirmar ausência:', error);
        throw error;
    }
}

export async function sair() {
    try {
        await auth.signOut();
        console.log('Usuário deslogado com sucesso');
    } catch (error) {
        console.error('Erro ao deslogar:', error);
    }
}

export function useLogout() {
    const [loading, setLoading] = useState(false);

    const sair = async () => {
        setLoading(true);
        try {
            await auth.signOut();
            console.log('Usuário deslogado com sucesso');
        } catch (error) {
            console.error('Erro ao deslogar:', error);
        } finally {
            setLoading(false);
        }
    };

    return { sair, loading };
}

export async function adicionarUsuariosNaColecaoNomes() {
  const user = auth.currentUser;

  if (user) {
    const userId = user.uid;
    const userEmail = user.email;
    try {
      const userDocRef = doc(db, "nomes", userId);
      const userSnapshot = await getDoc(userDocRef);

      if (!userSnapshot.exists()) {
        await setDoc(userDocRef, { userId: userId, userEmail: userEmail, });

        const convidadosCollectionRef = collection(db, `nomes/${userId}/convidados`);
        await addDoc(convidadosCollectionRef);
      } else {
      }
    } catch (error) {
      console.error("Erro ao adicionar usuários na coleção nomes:", error);
    }
  } else {
  }
}

