import { useEffect, useState } from "react";
import "./carrinho.css";
import { auth } from "../../config/firebase/firebase";
import { pegarPresentesTempoReal, adicionarItem, removerItem } from "../../servicos/firestore";
import Button from 'react-bootstrap/Button'; 
import Spinner from 'react-bootstrap/Spinner';
import { initMercadoPago } from '@mercadopago/sdk-react';
import axios from 'axios';

export default function Carrinho() {
    const [carrinho, setCarrinho] = useState([]);
    const [total, setTotal] = useState(0);
    const [confirmado, setConfirmado] = useState(false); // Estado para controle de confirmação
    const [loadingWallet, setLoadingWallet] = useState(false);

    useEffect(() => {
        const user = auth.currentUser;

        if (user) {
            pegarPresentesTempoReal(setCarrinho);
        } else {
            console.log('Usuário não autenticado');
        }
    }, []);

    useEffect(() => {
        let newTotal = 0;
        carrinho.forEach(item => {
            newTotal += parseFloat(item.preco) * parseInt(item.quantidade);
        });
        setTotal(newTotal);
    }, [carrinho]);

    const handleAdicionarItem = async (titulo, preco) => {
        await adicionarItem(titulo, preco);
    };

    const handleRemoverItem = async (titulo) => {
        await removerItem(titulo);
    };    

    // Início das Funcionalidades do Mercado Pago

    initMercadoPago('APP_USR-85827164-5705-4889-ab21-5e0f40bd843b', {
        locale: 'pt-BR',
        payment_methods: {
            excluded_payment_methods: [],
            excluded_payment_types: [],
        },
    });

    const createPreference = async () => {
        try {
            const items = carrinho.map(item => ({
                title: item.titulo,
                quantity: Number(item.quantidade),
                unit_price: parseFloat(item.preco),
                currency_id: 'BRL',  
            }));
    
            const response = await axios.post('https://api.mercadopago.com/checkout/preferences', {
                items: items,
                back_urls: {
                    success: 'https://casamentoln.com.br/pagamento-confirmado',
                    failure: 'https://casamentoln.com.br/pagamento-negado',
                    pending: 'https://casamentoln.com.br/pagamento-pendente',
                },
                auto_return: 'approved',
                payment_methods: {
                    excluded_payment_types: [],
                    installments: 12,
                    excluded_payment_methods: [],
                },
            }, {
                headers: {
                    Authorization: `Bearer APP_USR-4180781559507931-061315-40d87e8f71f142ac5c913dc32f788399-759958943`,
                    'Content-Type': 'application/json',
                },
            });
    
            const id = response.data.id;
            return id;
        } catch (error) {
            console.error(error.response.data);
        }
    };

    const handleBuy = async () => {
        try {
            setLoadingWallet(true); // Ativar indicador de carregamento

            const id = await createPreference();
            if (id) {
                window.location.href= `https://www.mercadopago.com.br/checkout/v1/redirect?preference-id=${id}`;
            }
        } catch (error) {
            alert('Erro ao processar compra, favor, entrar em contato via Whatsapp');
            // Tratar o erro aqui, exibir uma mensagem para o usuário, etc.
        } finally {
            setLoadingWallet(false); // Desativar indicador de carregamento após finalizar
        }
    };

    // Fim das Funcionalidades do Mercado Pago

    return (
        <div id="lista" className="carrinho">
            <h1 className="color-466D62">Carrinho</h1>
            <div className="container-fluid">
                {carrinho.length > 0 ? (
                <div className="container-fluid">
                    <div className="listagem">  
                        <div className="desc-lista" data-aos="zoom-in">
                            <div className="custom-list-table">
                                <div>
                                    {carrinho.map(item => (
                                        <div key={item.id}>
                                            <div className="desc-table">
                                                <p className="custom-th" scope="col">Presente</p>
                                                <p className="custom-th" scope="col">Quantidade</p>
                                                <p className="custom-th" scope="col">Preço Total</p>
                                            </div>
                                            <div className="desc-itens">
                                                <p className="titulo-item">{item.titulo}</p>
                                                <p className="qtd-item">
                                                    <button className="btn btn-secondary" onClick={() => handleAdicionarItem(item.titulo, item.preco)}>+</button>
                                                        <span>{item.quantidade}</span>
                                                    <button className="btn btn-secondary" onClick={() => handleRemoverItem(item.titulo)}>-</button>
                                                </p>
                                                <p className="preco-item">R$ {parseFloat(item.preco) * parseInt(item.quantidade)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="finalizacao" data-aos="zoom-in">
                            <div className="custom-list-table">
                                <h1>
                                    Resumo do Pedido
                                </h1>
                                <div className="desc-table">
                                    <p className="custom-th" scope="col">Presentes</p>
                                    <p className="custom-th" scope="col">Qtd x Preço</p>
                                    <p className="custom-th" scope="col">Preço Total</p>
                                </div>
                                <div className="all-items">
                                    {carrinho.map(item => (
                                        <div className="desc-itens" key={item.id}>
                                            <p className="titulo-item">{item.titulo}</p>
                                            <p className="qtd-item-modal"><span>{item.quantidade}x{item.preco}</span></p>
                                            <p className="preco-item">R$ {parseFloat(item.preco) * parseInt(item.quantidade)}</p>
                                        </div>
                                    ))}
                                </div>
                                <p className="custom-subtotal">
                                    Subtotal: R$ <span>{total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="aviso">
                        <p>Importante: Todos os presentes são ilustrativos e seu preço correspondente será convertido em dinheiro para os noivos</p>
                    </div>
                    <div className="buttons-confirm">
                        {loadingWallet ? (
                            <Button className="btn-confirmar" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Carregando...
                            </Button>
                        ) : (
                            <Button className="btn-confirmar" onClick={handleBuy}>
                                Confirmar Pedido
                            </Button>
                        )}
                    </div>
                </div>
                ) : (
                    <div className="carrinho-vazio" data-aos="zoom-in">
                        <div className="div-carrinho-vazio">
                            <a href="#lista-component">
                                <img width={300} title="Carrinho vazio" alt="Lista de presentes vazia" className="img-fluid" src="../../../images/carrinho-vazio (3)-min.png"/>
                                <Button className="btn btn-confirmar btn-vazio">
                                        Carrinho vazio, Clique para preencher
                                </Button>
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
