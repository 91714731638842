import "./photos.css"

export default function Photos(props) {
    return(
        <>
            {props.hor ? (
                <div className="div-images div-images-hor" data-aos="zoom-out" data-aos-duration="1000">
                    <img className="img-fluid custom-images custom-horizon-pics" src={props.url}/>
                </div>
            ) : (
                <div className="div-images" data-aos="zoom-out" data-aos-duration="1000">
                    <img className="img-fluid custom-images" src={props.url}/>
                </div>
            )}
        </>
        
    )
}