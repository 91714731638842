import "./textphoto.css"

export default function TextPhoto(props) {
    return (
        <>
            {props.reverse ? (
                <div className="text-and-pick container-fluid">
                    <div className="desc-pick" data-aos="zoom-in" data-aos-duration="1000">
                        <h4>
                            Com as bênçãos de Deus, em 20 de agosto de 2022, decidimos dar início à nossa história. 
                            Assim, começamos uma caminhada sempre debaixo da soberana vontade de Deus, 
                            com uma vontade convicta: a de estarmos sempre unidos em um só propósito. 
                            Hoje, convidamos vocês, familiares e amigos, para celebrar a nossa união. 
                            Nosso coração está cheio de alegria em partilhar nosso testemunho fiel de que, até aqui, o Senhor nos ajudou.
                        </h4>
                    </div>
                    <div className="pick custom-pick" data-aos="flip-right" data-aos-duration="1000">
                        <img className="img-fluid" src={props.url} loading="lazy"/>
                    </div>
                </div>
                ) : (
                    <div className="text-and-pick container-fluid reverse">
                        <div className="desc-pick" data-aos="zoom-in" data-aos-duration="1000">
                            <h4>
                            Àquele que é capaz de fazer infinitamente mais do que tudo o que pedimos ou pensamos, 
                            segundo o seu poder que atua em nós. <br/> Efésios 3:20
                            </h4>
                        </div>
                        <div className="pick custom-pick" data-aos="flip-left" data-aos-duration="1000">
                            <img className="img-fluid" src={props.url} loading="lazy"/>
                        </div>
                    </div>
                )
            }
        </>
    )
}