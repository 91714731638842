import React, { useState, useEffect } from 'react';
import "./contador.css";

export default function Contador() {
  const targetDate = new Date('2024-11-15T17:00:00'); // Data alvo
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function calculateTimeRemaining() {
    const currentDate = new Date();
    let timeDifference = targetDate - currentDate;

    // Se a diferença for negativa, significa que o tempo passou
    if (timeDifference < 0) {
      timeDifference = 0;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  return (
    <div className='contador-component'>
      <div className="contador-item" data-aos="flip-up" data-aos-duration="3000" data-aos-delay="500">
        <span className="contador-number">{String(timeRemaining.days).padStart(2, '0')}</span>
        <span className="contador-label">dias</span>
      </div>
      <div className="contador-item" data-aos="flip-up" data-aos-duration="2500" data-aos-delay="400">
        <span className="contador-number">{String(timeRemaining.hours).padStart(2, '0')}</span>
        <span className="contador-label">horas</span>
      </div>
      <div className="contador-item" data-aos="flip-up" data-aos-duration="2000" data-aos-delay="300">
        <span className="contador-number">{String(timeRemaining.minutes).padStart(2, '0')}</span>
        <span className="contador-label">minutos</span>
      </div>
      <div className="contador-item" data-aos="flip-up" data-aos-duration="1500" data-aos-delay="200">
        <span className="contador-number">{String(timeRemaining.seconds).padStart(2, '0')}</span>
        <span className="contador-label">segundos</span>
      </div>
    </div>
  );
};
