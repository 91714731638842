import './mensagem.css';
import { Button } from 'react-bootstrap';
import { esvaziarCarrinho } from '../../servicos/firestore';
import { useEffect } from 'react';

export default function Mensagem(props){
    useEffect(() => {
        if (props.esvaziarCarrinho) {
            esvaziarCarrinho(); 
        }
    }, [props.esvaziarCarrinho]);

    return(
        <div className="mensagem">
            {props.mensagem}
            <Button
              className="btn btn-pag-inicial"
            >
                <a className="animation-span redirect-to-home" href="/">Voltar para a página inicial</a>
            </Button>
        </div>
    )
}