import './App.css';
import { AppRoutes } from './routes/AppRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react"

function App() {

  useEffect(() => {
    AOS.init();
  }, [])
  
  return (
    <div className="App">
      <AppRoutes/>
    </div>
  );
}

export default App;
