import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase/firebase";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { AuthErrorCodes } from "firebase/auth";
import "./login.css";
import { Spinner } from "react-bootstrap";
import { adicionarUsuariosNaColecaoNomes } from "../../servicos/firestore";
export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user, navigate]);

    function handleSignIn(e) {
        e.preventDefault();
        if (!email || !password) {
            alert("Por favor, preencha todos os campos.");
            return;
        }
        signInWithEmailAndPassword(email, password);
    }

    function errosFirebase(error) {
        let mensagem = '';
        switch (error.code) {
            case AuthErrorCodes.INVALID_EMAIL:
                mensagem = "Email inválido";
                break;
            case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
                mensagem = "Credenciais Inválidas";
                break;
            default:
                mensagem = "Erro desconhecido";
        }
        return mensagem;
    }

    return (
        <div className="container">
            <header className="header">
            </header>
            <div className="div-login-img">
                <img data-aos="zoom-in" data-aos-duration="1000" className="img-fluid login-img" src="../../../images/fotor-20240808144040-min.png" alt="Casamento"/>
            </div>

            <form className="form-login" onSubmit={handleSignIn} data-aos="zoom-in" data-aos-duration="1000">
                <div className="inputContainer">
                    <label htmlFor="email">E-mail</label>
                    <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="email@casamento.com"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                </div>

                <div className="inputContainer">
                    <label htmlFor="password">Senha</label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="•••••••••••••"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                </div>

                <button type="submit" className="button login-button" disabled={loading}>
                    {loading ? 
                        (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Entrando
                            </>
 
                            
                        ) : ('Entrar')}
                </button>

                {error && <p className="error">{errosFirebase(error)}</p>}
            </form>
        </div>
    );
}
