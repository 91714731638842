import "./presentes.css";
import { salvarPresente, pegarPresentes, pegarPresentesTempoReal, adicionarItem } from "../../servicos/firestore";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from "../../config/firebase/firebase";
import { Button, Spinner } from "react-bootstrap";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import axios from 'axios';

export default function Presentes(props) {
    const navigate = useNavigate();
    const [carrinho, setCarrinho] = useState([]);
    const [adicionado, setAdicionado] = useState(false);
    const [mostrarCheck, setMostrarCheck] = useState(false);
    const [presentes, setPresentes] = useState([]);
    const [loadingCompra, setLoadingCompra] = useState(false);

    useEffect(() => {
        const user = auth.currentUser;

        if (user) {
            pegarPresentesTempoReal(setCarrinho);
        } else {
            console.log('Usuário não autenticado');
        }
    }, []);

    const handleAdicionarItem = async (titulo, preco) => {
        try {
            setAdicionado(true);
            await adicionarItem(titulo, preco);

        } catch (error) {
            alert('Erro ao adicionar item no carrinho, favor, entrar em contato via whatsapp');
        } finally {
            setMostrarCheck(true);
            setTimeout(() => {
                setMostrarCheck(false);
                setAdicionado(false); // Restaurar estado para exibir "Adicionar"
            }, 1000);
        }
    };

    // Mercado Pago Initialization
    initMercadoPago('APP_USR-85827164-5705-4889-ab21-5e0f40bd843b', {
        locale: 'pt-BR',
        payment_methods: {
            excluded_payment_methods: [],
            excluded_payment_types: [],
        },
    });

    const createPreferenceForGift = async (gift) => {
        try {
            setLoadingCompra(true);
            const response = await axios.post('https://api.mercadopago.com/checkout/preferences', {
                items: [{
                    title: gift.titulo,
                    quantity: 1,
                    unit_price: parseFloat(gift.preco),
                    currency_id: 'BRL',
                }],
                back_urls: {
                    success: 'https://casamentoln.com.br/agradecimento',
                    failure: 'https://casamentoln.com.br/agradecimento',
                    pending: 'https://casamentoln.com.br/pendente',
                },
                auto_return: 'approved',
                payment_methods: {
                    excluded_payment_types: [],
                    installments: 12,
                    excluded_payment_methods: [],
                },
            }, {
                headers: {
                    Authorization: `Bearer APP_USR-4180781559507931-061315-40d87e8f71f142ac5c913dc32f788399-759958943`,
                    'Content-Type': 'application/json',
                },
            });

            setLoadingCompra(false);
            // Retornar o preferenceId para abrir a URL do Mercado Pago
            return response.data.id;
        } catch (error) {
            console.error(error.response.data);
            setLoadingCompra(false);
        }
    };

    function verLista() {
        navigate("#lista");
    }

    return (
        <div className="item">
            <div className="item-pic">
                <img className="img-fluid" src={props.img} alt={props.titulo} />
            </div>
            <div className="item-info">
                <h5>{props.titulo}</h5>
                <p>R$ {props.preco}</p>
            </div>
            <div className="buttons-lista">
                <Button
                    className="btn btn-lista custom-buy-btn"
                    onClick={async () => {
                        const preferenceId = await createPreferenceForGift(props); // Usando props diretamente
                        if (preferenceId) {
                            window.location.href=`https://www.mercadopago.com.br/checkout/v1/redirect?preference-id=${preferenceId}`;
                        }
                    }}
                >
                    {loadingCompra ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                        <span className="animation-span">
                            <span>
                                Comprar
                            </span>
                        </span>
                    )}
                </Button>
                {adicionado ? (
                    <Button className="btn btn-lista custom-cart-btn" disabled>
                        {mostrarCheck ? (
                            <span className="check-animation"></span>
                        ) : (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        {mostrarCheck ? '' : ''}
                    </Button>
                ) : (
                    <Button className="btn btn-lista custom-cart-btn" onClick={() => handleAdicionarItem(props.titulo, props.preco)}>
                        <span className="animation-span">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" 
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart"><circle cx="9" 
                                cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6">
                                </path></svg>
                            </span>
                            <span className="add-to-cart">+</span>
                        </span>
                    </Button>
                )}
                <a className="btn btn-lista custom-link hook-list" href="#lista">
                    <span className="animation-span">Ver lista</span>
                </a>
            </div>
        </div>
    );
}
