import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAzcYrvxmFIV7oRSnkPSiGMfH4V3GPiE1Q",
    authDomain: "casamento-nl.firebaseapp.com",
    projectId: "casamento-nl",
    storageBucket: "casamento-nl.appspot.com",
    messagingSenderId: "424663775256",
    appId: "1:424663775256:web:945f308b3609f5b5cf2b6d",
    measurementId: "G-Y49PW2786C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);

export { app, auth, db };