import "./header.css";
import { sair, useLogout } from "../../servicos/firestore";
import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';

export default function Header(props) {
  const { sair } = useLogout();

  const handleLogout = async () => {
    await sair();
  };

  return (
    <div className="header">
      {props.add && (
        <>
          <h1 className="logo-nl">L N</h1>
          
          <Navbar bg="" expand={false} className="d-md-none">
            <Container fluid className="justify-content-end">
              <div className="d-flex align-items-center">
                <span className="span-cart">
                  <a href="#lista"><img className="custom-icon cart" src="../../../images/presente.png" alt="Presente" loading="lazy"/></a>
                </span>
                <Navbar.Toggle aria-controls="offcanvasNavbar" className="ms-2" />
              </div>
              <Navbar.Offcanvas
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id="offcanvasNavbarLabel">Menu de navegação</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="navbar-nav custom-ul-navbar ul-offcanvas">
                    <Nav.Link className="nav-item custom-nav-item" href="#evento">O Evento</Nav.Link>
                    <Nav.Link className="nav-item custom-nav-item" href="#lista-component">Presentes</Nav.Link>
                    <Nav.Link className="nav-item custom-nav-item" href="#fotos">Fotos</Nav.Link>
                    <Nav.Link className="nav-item custom-nav-item" target="_blank" href="https://wa.me/+5585985304539">Contato</Nav.Link>
                    <Button className="btn btn-logout-offcanvas" onClick={handleLogout}>
                      Sair <img className="custom-icon-logout" src="../../../images/logout.png" alt="Logout" />
                    </Button>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </>
      )}
      
      {/* Navegação para telas menores (sem offcanvas) */}
      {!props.add && (
        <nav className="navbar navbar-expand-lg d-md-none">
          <ul className="navbar-nav custom-ul-navbar">
            <li className="nav-item custom-nav-item"><a href="#evento">O Evento</a></li>
            <li className="nav-item custom-nav-item"><a href="#lista-component">Presentes</a></li>
            <li className="nav-item custom-nav-item"><a href="#fotos">Fotos</a></li>
            <li className="nav-item custom-nav-item"><a target="_blank" href="https://wa.me/+5585985304539">Contato</a></li>
          </ul>
        </nav>
      )}

      {/* Navegação para telas maiores */}
      <nav className="navbar navbar-expand-lg d-none d-md-flex">
        <ul className="navbar-nav custom-ul-navbar">
          <li className="nav-item custom-nav-item"><a href="#evento">O Evento</a></li>
          <li className="nav-item custom-nav-item"><a href="#lista-component">Presentes</a></li>
          <li className="nav-item custom-nav-item"><a href="#fotos">Fotos</a></li>
          <li className="nav-item custom-nav-item"><a target="_blank" href="https://wa.me/+5585985304539">Contato</a></li>
        </ul>
        {props.add && (
          <>
            <span className="span-cart">
              <a href="#lista"><img className="custom-icon cart" src="../../../images/presente.png" alt="Presente" /></a>
            </span>
            <button className="btn custom-btn-logout" onClick={handleLogout}>
              <img className="custom-icon-logout" src="../../../images/logout.png" alt="Logout" />
            </button>
          </>
        )}
      </nav>
    </div>
  );
}
