import "./home.css";
import Contador from "../Contador/Contador";
import TextPhoto from "../TextPhoto/TextPhoto";
import Header from "../Header/Header";
import Endereco from "../Endereco/Endereco";
import Photos from "../Photos/Photos";
import Lista from "../Lista/Lista";
import Carrinho from "../Carrinho/Carrinho";
import Footer from "../Footer/Footer";
import {
  adicionarUsuariosNaColecaoNomes,
  confirmarPresenca,
  verificarConfirmacaoPresenca,
  verificarRecusaPresenca,
  confirmarAusencia,
  recuperarNomesConfirmados,
  atualizarPresencaEAusencia, 
  salvarConfirmacaoPresenca,
  verificarCheck,
} from "../../servicos/firestore";
import { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { auth, db } from "../../config/firebase/firebase";
import { collection, getDocs } from "firebase/firestore";

export default function Home() {
  const [confirmado, setConfirmado] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [convidados, setConvidados] = useState([]);
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [loadingConvidados, setLoadingConvidados] = useState(true);

  useEffect(() => {
    adicionarUsuariosNaColecaoNomes(); // Assegura que a coleção está atualizada
    fetchConvidados();

    const verificarEAtualizarEstado = async () => {
      const user = auth.currentUser;

      if(user) {
        const userId = user.uid;
        const jaConfirmado = await verificarCheck(userId);

        setShowModal(!jaConfirmado);
      }
    };

    verificarEAtualizarEstado();
  }, []);

  const fetchConfirmados = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userId = user.uid;
        const confirmadosList = await recuperarNomesConfirmados();
        
        const confirmadosSet = new Set(confirmadosList);
        setSelectedIds(confirmadosSet);
      } catch (error) {
        console.error("Erro ao buscar confirmações:", error);
      }
    }
  };

  const fetchConvidados = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userId = user.uid;
        const convidadosRef = collection(db, `nomes/${userId}/convidados`);
        const convidadosSnapshot = await getDocs(convidadosRef);

        if (convidadosSnapshot.empty) {
          console.log("Nenhum convidado encontrado.");
          setConvidados([]);
          setLoadingConvidados(false);
          return;
        }

        const convidadosList = convidadosSnapshot.docs.map((doc) => {
          const data = doc.data();
          const nomes = Object.keys(data).filter((key) => key !== "selected");
          return nomes.map((nome) => ({
            id: doc.id,
            name: nome,
            selected: data[nome]?.selected || false,
          }));
        }).flat();

        setConvidados(convidadosList);
        await fetchConfirmados(); // Atualiza os checkboxes com base nos confirmados
      } catch (error) {
        console.error("Erro ao buscar convidados:", error);
      } finally {
        setLoadingConvidados(false);
      }
    }
  };

  const handleConfirm = async (event) => {
    event.preventDefault(); // Previne o comportamento padrão do formulário

    if (loadingConvidados) {
        alert("Aguarde o carregamento dos dados.");
        return;
    }

    const user = auth.currentUser;
    if (!user) return;

    const userId = user.uid;

    // Captura o formulário e os inputs
    const form = event.target;
    const inputs = form.querySelectorAll("input[type='checkbox']");

    const nomesSelecionados = [];
    const nomesAusentes = [];

    inputs.forEach((input) => {
        if (input.checked) {
            nomesSelecionados.push(input.name);
        } else {
            nomesAusentes.push(input.name);
        }
    });

    setLoadingConfirm(true);
    try {
        // Atualiza a presença e ausência
        await confirmarPresenca(userId, nomesSelecionados);
        await confirmarAusencia(userId, nomesAusentes);
        await salvarConfirmacaoPresenca(userId);

        setConfirmado(true);
    } catch (error) {
        alert("Erro ao confirmar presença, entre em contato via WhatsApp!");
    } finally {
        setLoadingConfirm(false);

        setTimeout(() => {
            setConfirmado(false);
            setShowModal(false);
        }, 2000);
    }
  };


  const toggleSelect = (id) => {
    setSelectedIds((prevSelectedIds) => {
      const updatedIds = new Set(prevSelectedIds);
      if (updatedIds.has(id)) {
        updatedIds.delete(id);
      } else {
        updatedIds.add(id);
      }
      return updatedIds;
    });
  };

  return (
    <>
      <header className="App-header">
        <Header add />
      </header>
      <section className="background-green container-fluid">
        <div className="apresentation-component container-fluid">
          <div className="text" data-aos="fade-right" data-aos-duration="1000">
            <p>Vem Casamento</p>
            <h2>Luziane & Nícolas</h2>
            <h5>15.11.2024</h5>
            <Button
              className="btn btn-confirmar"
              onClick={() => setShowModal(true)}
              disabled={loadingConfirm}
            >
              <span className="animation-span">
                {loadingConfirm ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Confirmar Presença"
                )}
              </span>
            </Button>
          </div>
          <div className="pick pick-init" data-aos="fade-left" data-aos-duration="1000">
            <img
              className="img-fluid"
              src="../../../images/DSC00926-min.jpg"
              alt="Casamento"
              loading="lazy"
            />
          </div>
        </div>
        <div className="contador container-fluid">
          <h3>Tempo restante para o grande dia...</h3>
          <Contador />
        </div>
        <div className="container-fluid">
          <TextPhoto url="../../../images/DSC00998-min.jpg"/>
          <TextPhoto url="../../../images/DSC00907-min.jpg" reverse />
          <div className="div-horizon-pics">
            <Photos hor url="../../../images/DSC00913-min.jpg" />
            <Photos hor url="../../../images/DSC01053-min.jpg" />
            <Photos hor url="../../../images/DSC00961-min.jpg" />
          </div>
        </div>
      </section>
      <section id="evento" className="container-fluid background-white">
        <div className="endereco-component">
          <Endereco />
        </div>
      </section>
      <section id="fotos" className="photos-component container-fluid">
        <h1>Fotos</h1>
        <div className="container-fluid fotos-div">
          <Photos url="../../../images/DSC00876-min.jpg" />
          <Photos url="../../../images/DSC00886-min.jpg" />
          <Photos url="../../../images/DSC00858-min.jpg" />
          <Photos url="../../../images/DSC00931-min.jpg" />
          <Photos url="../../../images/DSC00955-min.jpg" />
          <Photos url="../../../images/DSC00983-min.jpg" />
          <Photos url="../../../images/DSC00918-min.jpg" />
          <Photos url="../../../images/DSC00965-min.jpg" />
          <Photos url="../../../images/DSC01072 (1)-min.jpg" />
        </div>
      </section>
      <section id="lista-component" className="container-fluid lista-component">
        <h1>Lista de Presentes</h1>
        <p className="aviso-compra">
          Queridos amigos e familiares,
          por favor, realizem a compra dos presentes exclusivamente pelo nosso site. 
          Pois todos os presentes são ilustrativos e seu preço correspondente será convertido em dinheiro para os noivos
        </p>
        <Lista />
      </section>
      <section className="container-fluid">
        <Carrinho />
      </section>
      <footer>
        <Footer />
      </footer>

      <Modal
        className="modal-lg"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header
          className="custom-modal-confirmacao-header"
          closeButton
        >
          <Modal.Title>Confirmação de Presença</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-confirmacao-body">
          <p>Selecione as pessoas que estarão presentes no casamento</p>
          <form className="form-convidados" onSubmit={handleConfirm}>
            <div className="div-pai-convidados">
              {convidados.map((convidado) => (
                <div
                  className="div-convidados"
                  key={`${convidado.id}-${convidado.name}`}
                >
                   <input
                        type="checkbox"
                        name={convidado.name}
                        id={convidado.name}
                        checked={selectedIds.has(convidado.name)}
                        onChange={() => toggleSelect(convidado.name)} // Add onChange handler
                    />
                    <label htmlFor={convidado.name}>{convidado.name}</label>
                </div>
              ))}
            </div>
            <Modal.Footer className="custom-modal-confirmacao-footer">
              <Button
                className="btn-confirmar-presenca"
                type="submit"
                disabled={loadingConfirm}
              >
                {loadingConfirm ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : confirmado ? (
                  <span className="check-animation custom-check"></span>
                ) : (
                  "Confirmar"
                )}
              </Button>
              <Button className="btn-cancelar" variant="secondary" onClick={() => setShowModal(false)} disabled={loadingConfirm}>
                   Cancelar
               </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
